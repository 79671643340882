import * as React from "react"

import { MainLayout } from "../layouts/Main"
import { Seo } from "../components/Seo"
import { Container } from "../components/Container"
import { CV } from "../components/CV"
import { useTelegramLogger } from "../use/telegram-logger"

const IndexPage = () => {
  const logger = useTelegramLogger();
  logger.visit();

  return (
    <MainLayout>
      <Seo title="CV" />
      <Container>
        <CV />
      </Container>
    </MainLayout>
  )
}

export default IndexPage
