import * as React from "react"

import { useTelegramLogger } from "../../use/telegram-logger"

export const DownloadableButton = ({ file, children }) => {
  const logger = useTelegramLogger();

  const handleClick = () => {
    logger.download();
  }

  return (
    <a href={file} onClick={handleClick} className="downloadable-button" download>{children}</a>
  )
}
