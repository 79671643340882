import * as React from "react"

export const Education = ({
  name,
  location,
  positions,
}) => {
  return (
    <div className="education">
      <div className="education__name">
        {name}<span className="education__location">, {location}.</span>
      </div>
      <ul>
        {positions.map(({ period, title }) =>
          <li key={title}>{period} — {title}.</li>
        )}
      </ul>
    </div>
  )
}
