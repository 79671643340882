import * as React from "react"

export const Skills = ({
 skills
}) => {
  return (
    <div className="skills">
      <ul>
        {skills.map(({ groupName, list }) => (
          <li key={groupName}>
            <div className="skills__group">{groupName}:</div>
            {list.map((skill, i) =>
              typeof skill === "object"
                ? <span
                  key={skill.title}
                  style={{ color: '#7f7f7f' }}
                >{skill.title}{i < list.length - 1 ? "," : "."} </span>
                : <span
                  key={skill}
                >{skill}{i < list.length - 1 ? "," : "."} </span>
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}
