import * as React from "react"


export const Experience = ({
 title,
 time,
 positions
}) => {
  return (
    <div className="experience">
      <div className="experience__title">
        {typeof title === "object"
          ? <a href={title.link}>{title.value}</a>
          : title}
        {time && <span className="experience__time">&nbsp;({time}).</span>}
      </div>
      {positions.map(({ position, period, achievements }) => (
        <span key={position+period}>
          <div className="experience__subtitle">
            <span className="experience__position">{position}</span>,&nbsp;<span
            className="experience__period">{period}</span>.
          </div>
          <ul>
            {achievements.map((achievement) =>
              <li key={achievement}>{achievement}</li>
            )}
          </ul>
        </span>
      ))}
    </div>
  )
}
