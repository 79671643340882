import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

export const About = ({
  contacts,
  languages,
  location,
}) => {

  const renderContact = (type, value) => {
    switch (type) {
      case 'mail': {
        return <a href={`mailto:${value}`}>{value}</a>
      }
      case 'phone': {
        return <a href={`tel:${value}`}>{value}</a>
      }
      case 'link': {
        return <a href={`https://${value}`}>{value}</a>
      }
      default: {
        return value
      }
    }
  }
  return (
    <div className="about">
      <div className="about__info">
        <h3>Contacts:</h3>
        <ul>
          {contacts.map(({ title, value, type }) => (
            <li key={title}>{title}: {renderContact(type, value)}</li>
          ))}
        </ul>
        <h3>Languages:</h3>
        <ul>
          {languages.map(({ title, value, href }) => (
            <li key={title}>{title}: {href ? <a href={href}>{value}</a> : value}</li>
          ))}
        </ul>
        <h3 style={{ display: "inline-block" }}>Location:&nbsp;</h3>
        <span>{location}</span>
      </div>
        <StaticImage
          className="about__photo"
          src={'../../assets/images/photo.jpeg'}
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="photo"
        />
    </div>
  )
}
