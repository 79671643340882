import publicIp from 'public-ip';

const DOMAIN = 'Resume';
const TELEGRAM_DOMAIN = 'https://api.telegram.org';
const TOKEN = '1808332335:AAGuUqrKDSC47jbd4ry-tLMwHzAfJrKLUys';
const CHAT_ID = 506899669;

export const useTelegramLogger = (options = {}) => {
  const { token, chatId } = options;

  const visit = () => {
    log('Visit ✨');
  }

  const download = () => {
    log('Download 👍');
  }

  const log = async (...args) => {
    const ip = await publicIp.v4();
    const userAgent = window.navigator ? window.navigator.userAgent : '';

    const str = [`[${DOMAIN}]:`, ...args, `%0A%0A${ip}`, `%0A${userAgent}`].reduce((acc, val) => {
      if(typeof val === 'object') {
        return acc + ' ' + JSON.stringify(val);
      }
      return acc + ' ' + val;
    }, '');
    send(str);
  }

  const send = async (str) => {
    const link = `${TELEGRAM_DOMAIN}/bot${token || TOKEN}/sendMessage?chat_id=-${chatId || CHAT_ID}&text=${str}`;
    await fetch(link);
  }

  return {
    visit,
    download,
    log,
  }
}
