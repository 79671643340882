import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Experience } from "../Experience"
import { Education } from "../Education"
import { Skills } from "../Skills"
import { About } from "../About"
import { DownloadableButton } from "../DownloadableButton"

import cvFile from '../../assets/CV - Romanyuta.pdf'

const fullName = "Max Romanyuta"
const position = "Full Stack Engineer (Node.js/React)"
const aboutMe = "I like to solve problem. I always strive to expand my area of competence, self-motivated. Total experience in IT 6 years."

const contacts = [
  { title: "Email", value: "xom9ik.code@gmail.com", type: "mail" },
  { title: "LinkedIn", value: "linkedin.com/in/xom9ikk", type: "link" },
  { title: "Telegram", value: "t.me/xom9ik", type: "link" },
  { title: "GitHub", value: "github.com/xom9ikk", type: "link" },
  { title: "StackOverflow", value: "stackoverflow.com/users/7920683/xom9ikk", type: "link" }
]

const languages = [
  { title: "Russian", value: "Native" },
  { title: "English", value: "Intermediate (B1)", href: "https://www.efset.org/cert/K96WNu" }
]

const location = "Kyiv, Ukraine"

const skills = [{
  groupName: "Operating systems",
  list: ["macOS", "Linux (Ubuntu)"]
}, {
  groupName: "Programming languages",
  list: ["JavaScript", "TypeScript", "Golang", { title: "PHP", disable: true }, { title: "Java", disable: true }]
}, {
  groupName: "Frontend",
  list: ["React", "Next.js", "React Native", "redux", "redux-saga", "babel", "webpack", "sass", "BEM"]
}, {
  groupName: "Backend",
  list: ["Node.js", "NestJS", "fastify", "express", "knex", "pino", "AJV", "gRPC", "ws", "pm2", "zeroMQ", "swagger"]
}, {
  groupName: "Databases",
  list: ["PostgreSQL", "MongoDB", "Aerospike", "Redis"]
}, {
  groupName: "Testing",
  list: ["Jest", "Storybook", "loki", "enzyme", "redux-saga-test-plan"]
}, {
  groupName: "Specific",
  list: ["FFmpeg", "RTMP", "HLS", "WebRTC", "CUDA"]
}, {
  groupName: "Methodologies",
  list: ["Scrum", "Kanban", "TDD"]
}, {
  groupName: "Other",
  list: ["Git", "Bash", "NGINX", "Docker", "Prometheus", "Grafana", "Github Actions", "TeamCity"]
}]

const experience = [{
  title: "Playtika",
  positions: [{
    position: "Full Stack Developer",
    period: "Jul 2021 - now",
    achievements: [
      "Development of new features and modules with test coverage.",
      "Participating in code review, application architecture, design business logic."
    ]
  }]
}, {
  title: { value: "Verticals (pet-projects)", link: "https://verticals.xom9ik.com" },
  time: "11 months",
  positions: [{
    position: "Full Stack Developer",
    period: "May 2020 - Mar 2021",
    achievements: [
      "Designed architecture and developed backend.",
      "Designed interface and developed frontend.",
      "Configured CI/CD pipeline."
    ]
  }]
}, {
  title: "Achievements Collector (startup)",
  time: "8 months",
  positions: [{
    position: "Mobile Engineer",
    period: "Mar 2020 - Oct 2020",
    achievements: [
      "Developed a cross-platform mobile application for managing achievements in various spheres of life."
    ]
  }]
}, {
  title: "NDA",
  time: "1 year 3 months",
  positions: [{
    position: "Full Stack Developer",
    period: "Feb 2019 - Apr 2020",
    achievements: [
      "Designed architecture and developed backend.",
      "Developed a highly loaded system for streaming video (Twitch, Youtube analogue).",
      "Streaming/transcoding/stitching VR video (180-360 deg) for Cardboard/HTC/Oculus.",
      "Optimized delivery of content to clients.",
      "Consulted frontend developers.",
      "Service deployment."
    ]
  }]
}, {
  title: "Foodex",
  time: "1 year 9 months",
  positions: [{
    position: "Full Stack Developer",
    period: "Jul 2018 - Mar 2020",
    achievements: [
      "Consulted clients on business automation and optimisation.",
      "Managed and optimized production processes.",
      "Developed new functionality and supported.",
      "Developed content management system (CMS)."
    ]
  }, {
    position: "Android Developer",
    period: "Feb 2019 - Oct 2019",
    achievements: [
      "Development mobile application for a food delivery service for company's customers and couriers.",
      "Managed and improved IT infrastructure.",
      "Developed new functionality and supported.",
      "Developed customer relationship management (CRM)."
    ]
  }]
}]

const moreProjects = 7

const education = [{
  name: "Pre-Azov State Technical University",
  location: "Mariupol, Ukraine",
  positions: [{
    period: "2020-2021",
    title: "M.S. in Information Technologies"
  }, {
    period: "2016-2020",
    title: "Incomplete B.S. in Information Technologies"
  }]
}, {
  name: "Donetsk State University of Management",
  location: "Mariupol, Ukraine",
  positions: [{
    period: "2019-2020",
    title: "B.S. in Information Technologies"
  }]
}]


export const CV = () => {
  const data = useStaticQuery(
    graphql`
      query {
        currentBuildDate {
          currentDate
        }
      }
    `
  )

  return (
    <div className="cv">
      <div className="cv__download">
        <DownloadableButton file={cvFile}>
          Download PDF
        </DownloadableButton>
      </div>
      <h1>{fullName}</h1>
      <h2>{position}</h2>
      <About
        contacts={contacts}
        languages={languages}
        location={location}
      />
      <h3>Skills:</h3>
      <Skills skills={skills} />
      <h3>About me:</h3>
      <p>{aboutMe}</p>
      <h3>Experience:</h3>
      <div>
        {experience.map((row) => <Experience key={row.title} {...row} />)}
        {moreProjects && `${moreProjects} more...`}
      </div>
      <h3>Education:</h3>
      <div>
        {education.map((row) => <Education key={row.name} {...row} />)}
      </div>
      <div className="cv__date">{data.currentBuildDate.currentDate}</div>
    </div>
  )
}
